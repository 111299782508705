<template>
  <div class="my-invitate-container">

    <div class="lottery-list-model">
      <p class="lottery-list-all-size">共 {{ allInviteSize }} 名</p>
      <van-dropdown-menu :overlay="false">
        <van-dropdown-item
          class="van-dropdown-item1"
          v-model="value1"
          @change="changeValue1()"
          :options="option1"
        />
        <van-dropdown-item
          :title="value2"
          @open="calendarShow"
          ref="item"
        >
          <div class="van-calendar-title">
            <p class="van-calendar-title-year">{{ year }}年</p>
            <div class="van-calendar-title-mouth-content">
              <div
                @click="mouthPre"
                class="pre"
              ></div>
              <p class="van-calendar-title-mouth">{{ mouth + 1 }}月</p>
              <div
                @click="mouthNext"
                class="next"
              ></div>
            </div>
          </div>
          <van-calendar
            class="van-calendar"
            v-model="show"
            @confirm="onConfirm"
            :show-title="false"
            :show-confirm="false"
            :show-subtitle="false"
            position="top"
            :min-date="minDate"
            :max-date="maxDate"
          />
          <div class="van-calendar-bottom">
            <button
              @click="clearData()"
              class="van-calendar-footer-button"
            >清除</button>
          </div>

        </van-dropdown-item>
      </van-dropdown-menu>
      <el-table
        v-if="lotteryListInfo.length"
        class="lottery-list-table"
        :data="lotteryListInfo"
      >
        <el-table-column
          prop="uid"
          label="UID"
          width="62px"
        >
        </el-table-column>
        <el-table-column
          prop="nickname"
          label="昵称"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="是否实名"
          width="76px"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.status == 1 ? '否' : '是' }}</p>
          </template>

        </el-table-column>
        <el-table-column
          width="100px"
          prop="createTime"
          label="注册时间"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.createTime | timeFormate }}</p>
          </template>
        </el-table-column>
      </el-table>
      <div
        v-else
        class="col-center"
        style="width: 100%;margin-top: 120px;"
      >
        <img
          style="width:6.5rem;height:auto;margin-bottom:1.375rem;"
          src="../assets/icon/no-list.png"
        />
        <p style="font-size: 16px;font-family: lantingheiweight;font-weight: 500;color: #666666;line-height: 22px;">暂无数据</p>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import api from '../api/index-client'
import { DropdownMenu,DropdownItem,Calendar,Button } from 'vant';
import { Table,TableColumn } from 'element-ui';
Vue.use(Calendar);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Button);
Vue.use(Table)
Vue.use(TableColumn)

export default {
  data () {
    return {
      allInviteSize: '',
      value1: 0,
      value2: '注册时间',
      option1: [
        { text: '全部',value: 0 },
        { text: '实名',value: 2 },
        { text: '未实名',value: 1 },
      ],
      show: true,
      selectDate: '',
      year: null,
      mouth: null,
      minDate: new Date(2010,0,1),
      maxDate: new Date(2010,0,30),
      lotteryListInfo: [
      ],
    }
  },
  watch: {
    show () {
      if (!this.show) {
        this.$refs.item.toggle();
      }
    },
    mouth () {
      var endDay = null
      switch (this.mouth) {
        case 0:
          endDay = 31
          break;
        case 1:
          endDay = this.year % 4 == 0 ? 29 : 28
          break;
        case 2:
          endDay = 31
          break;
        case 3:
          endDay = 30
          break;
        case 4:
          endDay = 31
          break;
        case 5:
          endDay = 30
          break;
        case 6:
          endDay = 31
          break;
        case 7:
          endDay = 31
          break;
        case 8:
          endDay = 30
          break;
        case 9:
          endDay = 31
          break;
        case 10:
          endDay = 30
          break;
        case 11:
          endDay = 31
          break;
        default:
          break;
      }
      this.minDate = new Date(this.year,this.mouth,1);
      this.maxDate = new Date(this.year,this.mouth,endDay);
    }
  },
  mounted () {
    this.year = new Date().getFullYear()
    this.mouth = new Date().getMonth()
    this.getInviteList()
    this.$store.commit('HIDE_APPLOADING')
  },
  methods: {
    formatDate (date) {
      return `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}`;
    },
    onConfirm (date) {
      this.show = false;
      this.selectDate = this.formatDate(date);
      this.value2 = this.formatDate(date);
      this.getInviteList()
    },
    changeValue1 () {
      this.getInviteList()
    },
    calendarShow () {
      this.show = true
    },
    // 清除时间
    clearData () {
      this.value2 = '注册时间'
      this.selectDate = ''
      this.getInviteList()
      this.show = false
    },
    // 上一月
    mouthPre () {
      if (this.mouth == 0) {
        this.year -= 1
        this.mouth = 11
      } else {
        this.mouth -= 1
      }
      this.show = true
    },
    // 下一月
    mouthNext () {
      if (this.mouth == 11) {
        this.year += 1
        this.mouth = 0
      } else {
        this.mouth += 1
      }
      this.show = true
    },
    // 获取邀请记录
    getInviteList () {
      var url = this.value1 != 0 && this.selectDate ? 'user/getInviteList?status=' + this.value1 + '&createTime=' + this.selectDate + '&sort=create_time,DESC' :
        this.value1 != 0 ? 'user/getInviteList?status=' + this.value1 + '&sort=create_time,DESC' :
          this.selectDate ? 'user/getInviteList?createTime=' + this.selectDate + '&sort=create_time,DESC' :
            'user/getInviteList?sort=create_time,DESC'
      api
        .get(url)
        .then(result => {
          if (result.data.success) {
            this.lotteryListInfo = result.data.data.content
            this.allInviteSize = result.data.data.totalElements
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
  },
}
</script>
<style lang="less" scoped>
.my-invitate-container {
  margin-top: 3.875rem;
  .van-dropdown-item1 {
    /deep/.van-popup--top {
      width: 30%;
      margin-left: 20px;
      border-radius: 8px;
    }
  }
  .van-dropdown-menu {
    margin-top: 10px;
    width: 100%;

    /deep/.van-dropdown-menu__bar {
      background: #262626;
      box-shadow: none;
    }

    /deep/.van-dropdown-item__content {
      background: transparent;
      .van-dropdown-item__option {
        width: 100%;
        background: #424242;
      }
      .van-cell::after {
        display: none;
      }
      .van-dropdown-item__option--active {
        color: #ffffff !important;
        font-weight: 600;
      }
      .van-dropdown-item__option {
        color: #999999;
        font-weight: 400;
      }
      .van-cell__value {
        display: none;
      }
    }
    /deep/.van-dropdown-menu__item {
      margin: 0 31px 0 17px !important;
      flex: inherit !important;
      .van-dropdown-menu__title {
        font-size: 14px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 20px;
      }
    }
  }
  /deep/.van-overlay {
    background-color: rgba(0, 0, 0, 0);
  }
  .van-calendar-bottom {
    position: absolute;
    width: 94vw;
    height: 50px;
    bottom: 0;
    left: 3vw;
    z-index: 2050;
    background: #424242;
    border-top: 1px solid #5c5c5c;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border-radius: 0 0 8px 8px;
    .van-calendar-footer-button {
      width: 73px;
      height: 34px;
      background: #f3cdb6;
      border-radius: 4px;
      border: none;
      outline: none;
      margin: 10px 13px 10px 0;
      font-size: 14px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #1c1c1c;
      line-height: 20px;
    }
    .van-calendar-footer-button:hover {
      background: #f3cdb6;
    }
  }
  .van-calendar-title {
    position: absolute;
    width: 94vw;
    height: 50px;
    top: 0;
    left: 3vw;
    z-index: 2050;
    background: #424242;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px 8px 0 0;
    .van-calendar-title-year {
      font-size: 18px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 25px;
      margin-left: 14px;
    }
    .van-calendar-title-mouth-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .van-calendar-title-mouth {
        font-size: 14px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 20px;
        margin: 0 12px;
      }
      .pre {
        width: 8px;
        height: 8px;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: rotate(-135deg);
      }
      .next {
        width: 8px;
        height: 8px;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: rotate(45deg);
        margin-right: 16px;
      }
    }
  }
  .van-calendar {
    margin-top: 50px;
    width: 94vw;
    margin-left: 3vw;
    padding-top: 8px;
    height: 360px;
    background: #424242;
    box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.5);
    border-radius: 0 0 8px 8px;
    position: relative;

    /deep/.van-calendar {
      background: #424242;
      background-color: #424242;
    }
    /deep/.van-calendar__month-title {
      display: none;
    }
    /deep/.van-calendar__month-mark {
      display: none;
    }
    /deep/.van-popup__close-icon {
      display: none;
    }
    /deep/.van-calendar__weekday {
      font-size: 14px;
      font-family: PingFang-SC-Semibold, PingFang-SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 20px;
      padding-bottom: 10px;
    }
    /deep/.van-calendar__day {
      font-size: 16px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      height: 48px;
      color: #c2c2c2;
      line-height: 22px;
    }
    /deep/.van-calendar__selected-day {
      width: 33px;
      height: 33px;
      background: #f3cdb6;
      box-shadow: 0px 0px 10px 0px #f3cdb6;
      border-radius: 17px;
      font-size: 16px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #1c1c1c;
      line-height: 22px;
    }
  }
  .lottery-list-model {
    position: relative;
    margin-left: 3%;
    width: 94%;
    min-height: 80vh;
    padding-bottom: 50px;
    background: #262626;
    margin-top: 12px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .lottery-list-all-size {
      position: absolute;
      top: 22px;
      right: 16px;
      z-index: 100;
      font-size: 14px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #a5a5a5;
      line-height: 20px;
    }
    .lottery-list-title {
      margin-top: 18px;
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
      line-height: 28px;
    }
  }
  .lottery-list-table {
    margin-top: 13px;
    width: 94%;
    margin-left: 3%;
    background: #262626;
    border: 1px solid #3c3c3c;
    /deep/ tr,
    /deep/ th {
      background-color: #262626;
      border-bottom: 1px solid #3c3c3c;
    }
    /deep/th {
      font-size: 14px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #999999;
      line-height: 20px;
    }
    /deep/ td {
      border-bottom: 1px solid #3c3c3c;
      font-size: 14px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #999999;
      line-height: 20px;
    }
  }
  /deep/.lottery-list-table::before {
    display: none;
  }
}
</style>